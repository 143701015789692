<template>
  <StaticPagePug>
    <div class="form-wrapper">
      <div class="title">{{ $t('loginClient.header') }}</div>
      <div class="default-from-box"><div class="default-from-box-content"></div></div>
      <el-tabs v-model="activeName" class="tabs">
        <el-tab-pane :label="$t('common.field.email')" name="email">
          <div class="from-box">
            <el-form :model="loginForm" ref="loginForm" :rules="loginFormRules" :hide-required-asterisk="true" label-position="top" auto-complete="off">
              <div v-if="!needVerification" class="input_wrapper">
                <el-form-item :label="$t('common.field.emailAdd')" prop="userName_login">
                  <el-input
                    v-model.trim="loginForm.userName_login"
                    :placeholder="$t('common.field.emailAdd')"
                    autocomplete="off"
                    tabindex="1"
                    auto-complete="new-password"
                  ></el-input>
                </el-form-item>
                <el-form-item class="pass" :label="$t('common.field.pw')" prop="password_login">
                  <el-input
                    :type="passwordVisible ? 'text' : 'password'"
                    v-model="loginForm.password_login"
                    :placeholder="$t('common.field.pw')"
                    autocomplete="off"
                    tabindex="2"
                    @keyup.enter.native="loginSubmit"
                    class="suffix-input"
                    auto-complete="new-password"
                  >
                    <template slot="suffix">
                      <img src="@/assets/images/pug/eye-line.png" alt="view" @click="togglePasswordVisibility" />
                    </template>
                  </el-input>
                </el-form-item>
                <p class="forgotPass">
                  <router-link to="/forgetPassword">{{ $t('common.button.forgotPw') }}</router-link>
                </p>
                <el-button class="ok-btn" tabindex="3" @click.native="loginSubmit" data-testid="login">
                  {{ $t('common.button.login') }}
                </el-button>
                <p class="signUp">
                  <a href="https://www.puprime.partners/forex-trading-account/" target="_blank">{{ $t('common.button.signUp') }}</a>
                </p>
              </div>
              <div class="verification-wrapper" v-else>
                <slide-verify
                  :l="45"
                  :r="10"
                  :w="250"
                  :h="155"
                  :slider-text="$t('common.verification.toSlide')"
                  :imgs="imgs"
                  ref="slideblock"
                  @again="onAgain"
                  @success="onSuccess"
                  @fail="onFail"
                  @refresh="onRefresh"
                ></slide-verify>
              </div>
              <div v-show="msg" class="verfify-message">{{ msg }}</div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('common.field.mobileNumber')" name="mobileNumber">
          <div class="from-box">
            <el-form :model="phoneLoginForm" ref="phoneLoginForm" :rules="phoneLoginFormRules" :hide-required-asterisk="true" label-position="top" auto-complete="off">
              <div v-if="!needVerification">
                  <div class="mobile-wrap">
                  <div class="mobile-label">{{$t('common.field.mobileNumber')}}</div>
                  <el-row type="flex" justify="space-between" :gutter="8">
                    <el-col :span="8">
                      <CountryCodeSelect
                        v-model="phoneLoginForm.countrycode_login"
                        name="countrycode_login"
                        :codes="codes"
                        @select="handleSelect"
                        class="group-left-input"
                      />
                    </el-col>
                    <el-col :span="16">
                      <el-form-item prop="mobile_login">
                        <el-input
                          v-model.trim="phoneLoginForm.mobile_login"
                          :placeholder="$t('common.field.mobileNumber')"
                          autocomplete="off"
                          tabindex="1"
                          auto-complete="new-password"
                          class="group-right-input"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    
                  </el-row>  
                </div>
                <el-form-item class="pass" :label="$t('common.field.pw')" prop="password_login">
                  <el-input
                    :type="passwordVisible ? 'text' : 'password'"
                    v-model="phoneLoginForm.password_login"
                    :placeholder="$t('common.field.pw')"
                    autocomplete="off"
                    tabindex="2"
                    @keyup.enter.native="phoneLoginSubmit"
                    auto-complete="new-password"
                    class="suffix-input"
                  >
                    <template slot="suffix">
                      <img src="@/assets/images/pug/eye-line.png" alt="view" @click="togglePasswordVisibility" />
                    </template>
                  </el-input>
                </el-form-item>
                <p class="forgotPass">
                  <router-link to="/forgetPassword">{{ $t('common.button.forgotPw') }}</router-link>
                </p>
                <el-button class="ok-btn" tabindex="3" @click.native="phoneLoginSubmit" data-testid="login">
                  {{ $t('common.button.login') }}
                </el-button>
                <p class="signUp">
                  <a href="https://www.puprime.partners/forex-trading-account/" target="_blank">{{ $t('common.button.signUp') }}</a>
                </p>
              </div>
              <div class="verification-wrapper" v-else>
                <slide-verify
                  :l="45"
                  :r="10"
                  :w="250"
                  :h="155"
                  :slider-text="$t('common.verification.toSlide')"
                  :imgs="imgs"
                  ref="slideblock"
                  @again="onAgain"
                  @success="onSuccess"
                  @fail="onFail"
                  @refresh="onRefresh"
                ></slide-verify>
              </div>
              
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </StaticPagePug>
</template>

<script>
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';
import loginClientMixin from '@/mixins/page/loginClient';
import CountryCodeSelect from '@/components/form/CountryCodeSelect';

export default {
  name: 'loginClient',
  components: { StaticPagePug, CountryCodeSelect },
  mixins: [loginClientMixin]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/loginForm.scss';
@import '@/assets/css/pages/loginPug.scss';
</style>
