import { md5, rsa } from '@/util/encrypt.js'
import { apiSecurityPassed, apiGetUserLocation } from '@/resource'
import helper from '@/util/signinHelper'
import v1 from '@/assets/images/verification/v1.jpg'
import v2 from '@/assets/images/verification/v2.jpg'
import v3 from '@/assets/images/verification/v3.jpg'
import v4 from '@/assets/images/verification/v4.jpg'
import v5 from '@/assets/images/verification/v5.jpg'
import v6 from '@/assets/images/verification/v6.jpg'
import { validateNumber } from '@/util/validation';
import countryCodeEn from '@/assets/data/countrycode_en.json'
import countryCodeCn from '@/assets/data/countrycode_cn.json'

export default {
  data() {
    return {
      activeName: 'email',
      passwordVisible: false,
      needVerification: false,
      msg: '',
      imgs: [v1, v2, v3, v4, v5, v6],
      loginForm: {
        userName_login: '',
        password_login: '',
        gcaptchatoken: '',
      },
      loginFormRules: {
        userName_login: [
          {
            required: true,
            message: this.$t('loginClient.formValidation.emailReq'),
            trigger: 'blur',
          },
        ],
        password_login: [
          {
            required: true,
            message: this.$t('common.formValidation.pwReq'),
            trigger: 'blur',
          },
        ],
      },
      countryCodeEnJson: countryCodeEn,
      countryCodeCnJson: countryCodeCn,
      codes: [],
      phoneLoginForm: {
        countrycode_login: this.ctCode ? this.ctCode : '',
        mobile_login: this.mobileNumber ? this.mobileNumber : '',
        password_login: '',
      },
      phoneLoginFormRules: {
        countrycode_login: [
          {
            required: true,
            trigger: 'blur',
            validator: validateNumber,
          },
        ],
        mobile_login: [
          {
            required: true,
            trigger: 'blur',
            validator: validateNumber,
          },
        ],
        password_login: [
          {
            required: true,
            message: this.$t('common.formValidation.pwReq'),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  watch: {
    activeName(value) {
      if (value === 'mobileNumber') {
        this.getCode()
        this.getLoc()
      }
    },
  },
  computed: {
    failedSecondTime() {
      return this.$store.state.common.failedSecondTime
    },
    lang() {
      return this.$store.state.common.lang
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    invokeVerification() {
      this.needVerification = true
    },
    onSuccess() {
      apiSecurityPassed({
        data: rsa('feliscatus:' + Number(new Date())),
      })
        .then(resp => {
          this.needVerification = false
          this.$store.commit('common/setFailedSecondTime', false)
        })
        .catch(err => {
          console.log(err)
        })
      this.msg = ''
    },
    onFail() {
      this.msg = this.$t('loginClient.failedMasseage')
    },
    onRefresh() {
      this.msg = ''
    },
    loginSubmit() {
      this.$refs['loginForm'].validate(valid => {
        if (valid) this.checkIfAllowed(this.login)
      })
    },
    //检测到非人为操作滑动时触发的回调函数
    onAgain() {
      this.msg = this.$t('loginClient.failedMasseage')
      this.$refs.slideblock.reset()
    },
    checkIfAllowed(cb) {
      if (!this.failedSecondTime) {
        cb()
      } else {
        this.invokeVerification()
      }
    },
    login() {
      const userName_login = rsa(this.loginForm.userName_login)
      const password_login = md5(this.loginForm.password_login)
      helper.signIn(userName_login, password_login)
    },
    // phone登录方法
    handleSelect(item) {
      this.phoneLoginForm.countrycode_login = item.code
    },
    phoneLoginSubmit() {
      this.$refs['phoneLoginForm'].validate(valid => {
        if (valid) this.checkIfAllowed(this.phoneLogin)
      })
    },
    phoneLogin() {
      const countrycode_login = this.phoneLoginForm.countrycode_login
      const mobile_login = rsa(this.phoneLoginForm.mobile_login)
      const password_login = md5(this.phoneLoginForm.password_login)
      helper.signInMobile(countrycode_login, mobile_login, password_login)
    },
    getLoc() {
      let country = ''
      let countryCode
      apiGetUserLocation().then(resp => {
        let res = resp.data.split(/\n/g).filter(line => line.trim())
        for (const element of res) {
          if (element.slice(0, 3) === 'loc') {
            country = element.slice(4)
            let filteredCountry = this.countryCodeEnJson.filter(function (p) {
              return p.iso_country_code === country.toUpperCase()
            })
            countryCode = filteredCountry[0].code
          }
        }
        this.phoneLoginForm.countrycode_login = countryCode
      })
    },
    getCode() {
      if (this.lang === 'zh_CN') {
        this.codes = this.formatValues(this.countryCodeCnJson)
      } else {
        this.codes = this.formatValues(this.countryCodeEnJson)
      }
    },
    formatValues(dataValue) {
      return dataValue.map(e => {
        const [codeLabel, codeValue] = e.value.split('+')
        return {
          ...e,
          codeValue: `+${codeValue}`,
          codeLabel,
        }
      }).sort((a, b) => a.value.localeCompare(b.value))
    },
  },
  mounted() {
    setTimeout(function() {
      $('.default-from-box')
        .css('animation', 'none')
        .css('display', 'none')
      $('.from-box').css('display', 'block')
      $('.el-tabs').css('opacity', 1)
      $('.el-tabs__nav').attr('dir', 'ltr')
    }, 1000)
  },
}
