<template>
  <el-form-item :prop="name">
    <el-popover
      placement="bottom-start"
      v-model="showSelect"
      :visible-arrow="false"
      popper-class="country-code-drop-wrapper"
      width="160"
    >
      <el-input slot="reference" v-on="listeners" v-bind="$attrs" :placeholder="placeholder" readonly></el-input>
      <el-form @submit.native.prevent>
        <el-input v-model="searchValue" @input.native="onSearchLanguage" class="search-input" :placeholder="$t('common.search')">
          <template slot="suffix">
            <img src="@/assets/images/pug/search.png" alt="search" @click="onSearchLanguage" />
          </template>
        </el-input>
      </el-form>
      <div class="list-wrapper">
        <div
          v-for="(item, index) in codesList"
          :key="item.code + index"
          :class="['list-item', $attrs.value === item.code ? 'active' : '']"
          @click="() => onCodesSelect(item)"
        >
          <div class="view-container">
            <span class="view-value">{{ item.codeValue }}</span>
            <span class="view-label">{{ item.codeLabel }}</span>
          </div>
        </div>
      </div>
    </el-popover>
  </el-form-item>
</template>

<script>
export default {
  name: 'CountryCodeSelect',
  props: { name: String, placeholder: String, codes: Array },
  data() {
    return {
      focus: false,
      searchValue: '',
      showSelect: false,
      codesList: this.codes
    };
  },
  methods: {
    onSearchLanguage() {
      if (this.searchValue) {
        this.codesList = this.codes.filter(e => {
          const lowerCaseLabel = e.value.toLocaleLowerCase();
          const lowerCaseValue = this.searchValue.toLocaleLowerCase();
          return lowerCaseLabel.includes(lowerCaseValue);
        });
      }
    },
    onCodesSelect(itemData) {
      this.$emit('select', itemData);
      this.showSelect = false;
      this.searchValue = '';
    }
  },
  watch: {
    searchValue(val) {
      if (!val) {
        this.codesList = this.codes;
      }
    },
    codes(values) {
      this.codesList = values;
    }
  },
  computed: {
    visible() {
      return this.$attrs.value || this.focus || this.placeholder;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit('input', event),
        focus: event => (this.focus = true),
        blur: event => (this.focus = false)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/form/countryCodeSelect.scss';
</style>

<style lang="scss">
.country-code-drop-wrapper.el-popper {
  border-radius: 10px !important;
  margin-top: 4px !important;
  max-width: 350px;
  text-align: left;
  border: 1px solid #BBBABA;
  padding: 10px !important;
  ::-webkit-scrollbar {
    width: 2px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #EEE !important;
  }
}
</style>
